<template>
  <k-layout>
    <header class="tw-bg-white tw-pt-13 tw-pb-12 tw-px-4">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <h1 class="tw-heading-1">{{ $t('navigation.privacy_policy') }}</h1>
      </div>
    </header>

    <main class="tw-mt-8 tw-px-4 tw-mb-24">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <section class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-p-16">
          <div id="content" v-html="html"></div>
        </section>
      </div>
    </main>
  </k-layout>
</template>

<script>
import html from '!!raw-loader!./terms-and-conditions-page.html'
import html_en from '!!raw-loader!./terms-and-conditions-page-en.html'

import { getRouteLocale } from '@web/constants/language'

export default {
  computed: {
    locale() {
      return getRouteLocale()
    },
    html() {
      return this.locale ? html_en : html
    }
  }
}
</script>

<style lang="scss" scoped>
#content ::v-deep p {
  margin-top: 0.75rem !important;
}
</style>
